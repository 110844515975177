<template>
  <b-overlay :show="isLoading" rounded="lg">
    <b-card title="Daikin - Redelivery List">
      <b-col cols="12">
        <!--only show for mobile view, THIS IS SECOND PAGE MENU TO SHOW SORT DATA OPTIONS *if needed same with other mobile view-->
        <b-row class="hide-on-desktop" v-if="btnshow">
          
          <b-col cols="12">
            <b-row>
              <b-button
                size="lg"
                class="w-100 d-flex justify-content-center align-items-center"
                style="margin-bottom:15px;"
                variant="primary"
                @click="btnForMobile('Re-Delivery')"
              >
                Re-Delivery
              </b-button>
            </b-row>
          </b-col>
        </b-row>
        <!--END only show for mobile view-->
        <b-row :class="btnBackMobile">
          <b-button size="md" @click="actBackMobile" variant="primary">
            Back
          </b-button>
        </b-row>

        <!--this is options for search data, only "Filter by Status" will dissapear on mobile view-->
        <b-row>
          <b-col md="6" class="ml-md-auto hide-on-mobile">
            <strong>Filter by Status :</strong>
            <b-form-select
              @input="(value) => statusFiltering(value)"
              id="delivery-status"
              v-model="deliveryStatus"
              :options="listStatus"
              @click="filterDelivery"
            >
            </b-form-select>
          </b-col>
          <b-col md="6" :class="conditionView">
            <b-form-input
              
              v-model="search"
              type="search"
              placeholder="Search by DO"
              style="float: right; margin-top: 20px; margin-bottom: 30px;"
            />
          </b-col>
          <b-col :class="conditionView" class="mb-1">
            <b-button @click="doFiltering" variant="primary" style="margin-right: 10px;">search</b-button>
            <span style="color: red;"></span>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6"> </b-col>
          <b-col md="6" :class="conditionView">
            <b-row>
              <b-col md="10" cols="9">
                <b-form-datepicker
                  @input="(value) => fireFiltering(value)"
                  placeholder="Search by Delivery Date"
                  v-model="delivDate"
                  style="float: right;  margin-bottom: 30px;"
                />
              </b-col>
              <b-col md="2" cols="3">
                <b-button variant="danger" @click="cleanQuery">X</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!--END this is options for search data-->
        <b-row :class="conditionView">
          <b-col cols="12">
            <b-table
              responsive
              small
              hover
              bordered
              show-empty
              :items="filterDelivery"
              :fields="fields"
              :busy.sync="tableLoading"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :stacked="stackedStatus"
              ref="delivery"
            >
              <template #cell(index)="row">
                {{ row.index + 1 + (currentPage - 1) * 10 }}
              </template>

              <template v-slot:cell(deliveryDate)="row">
                {{ dates(row.item.deliveryDate) }}
              </template>
              <template #cell(actions)="row">
                <b-dropdown
                  id="dropdown-dropleft"
                  :dropleft="showDropdown"
                  variant="primary"
                  size="sm"
                  text="Menu"
                  no-caret
                >
                  <template #button-content>
                    <feather-icon size="1x" icon="MenuIcon" />
                  </template>
                  <b-dropdown-item
                    style="text-align:center"
                  >
                    <b-button
                      size="sm"
                      variant="info"
                      @click="toDetailPage(row.item)"
                      class="mr-1"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row :class="conditionView" style="margin-top:10px">
          <b-col md="9" />
          <b-col md="3">
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              @change="onPageChange"
              v-model="currentPage"
              align="fill"
              class="hide-on-desktop"
            />
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              @change="onPageChange"
              v-model="currentPage"
              class="hide-on-mobile"
              style="float: right"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/axios";
import { userAccess, dateFormat } from "@/utils/utils";
export default {
  data() {
    let mobileViewQuery = window.matchMedia("(max-width: 761px)"); //check true or false width screen mobile view
    return {
      mobileViewQuery: mobileViewQuery, //all info matchMedia
      mobileView: mobileViewQuery.matches, // return true or false match screen moobile view
      //FIELD FOR MOBILE
      btnshow: true,
      showDropdown: true,
      btnBackMobile: "hide-at-all",
      conditionView: "ml-md-auto hide-on-mobile",
      stackedStatus: "md",
      //sort direction list for mobile view
      directions: [
        { key: false, label: "Asc", sortable: true },
        { key: true, label: "Desc", sortable: true },
      ],
      //END FIELD FOR MOBILE
      fields: [
        { key: "index", label: "No" },
        { key: "sapSo", label: "SAP SO" },
        { key: "sapDo", label: "SAP DO" },
        { key: "deliveryDate", label: "Delivery Date" },
        { key: "status", label: "STATUS" },
        { key: "actions", label: "Actions" },
      ],
      shippingPoint:"1001",
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      isLoading: false,
      tableLoading: true,
      sortBy: "", //this for mobile view if needed same with other mobile view
      sortDesc: false, //this for mobile view if needed same with other mobile view
      // showSortBy : false, //this for mobile view if needed same with other mobile view
      search: "",
      selectData: "",
      listStatus: [
        "Re-Delivery"
      ],
      defaultStatus:"Re-Delivery",
      deliveryStatus: "",

      delivDate: "", //model for search data by delivery date
    };
  },
  computed: {
    filterDelivery() {
      var result = this.deliveryOrders;

      if (this.deliveryStatus == "All") {
        // result = result.filter(
        //   (x) =>
        //     x.status === "Returned" ||
        //     x.status === "Waiting Acknowledgment" ||
        //     x.status === "Waiting Approval" ||
        //     x.status === "Approved" ||
        //     x.status === "Return Completed"
        // );
      } else {
        result = result.filter((x) => x.status === this.deliveryStatus);
      }

      return result;
    },
    deliveryOrders() {
      var tempDO = this.$store.getters["delivery/getDO"].filter(
        (x) => x.shippingPoint === "1001"
      );
      return tempDO;
    },
    permission() {
      let result = userAccess("Return Delivery Daikin", "return_delivery_menu");
      // console.log("Return Delivery",result)
      return result;
    },
  },
  created() {
    document.title = "Redelivery | RSP";

    
  },
  mounted() {
    // this.$store.dispatch("delivery/getDeliveryOrder");
    //temporary for detail dropdown button conditional by mobile view or desktop view
    this.mobileViewQuery.addListener(() => {
      this.mobileView = this.mobileViewQuery.matches;
    });

    // Saving Menu Setting on localstorage session so it still same even after reloading the page
    if (this.$session.has("perPageRedelivery")) {
      this.perPage = this.$session.get("perPageRedelivery");
    }
    if (this.$session.has("sortByRedelivery")) {
      this.sortBy = this.$session.get("sortByRedelivery");
    }
    if (this.$session.has("sortDescRedelivery")) {
      this.sortDesc = this.$session.get("sortDescRedelivery");
    }
    var isMobile = window.matchMedia("(max-width: 761px)").matches;
    console.log("mobile is ", isMobile);
    var queryAvailable = -1;
    var query;
    if (this.$route.query.status) {
      query = this.$route.query.status;
      queryAvailable = this.listStatus.findIndex((x) => {
        return x == query;
      });
    }
    console.log("queryavailable ", queryAvailable);
    if (queryAvailable >= 0) {
      if (isMobile) {
        this.btnForMobile(query);
        return;
      }
      this.deliveryStatus = query;
    } else {
      this.deliveryStatus = this.defaultStatus;
    }
    if (this.$route.query.sapDo) {
      this.search = this.$route.query.sapDo;
      this.doFiltering()
    }
  },
  watch: {
    // Taking the Menu Setting from localstorage session so the setting will be the same as before
    perPage(perPageNew) {
      this.$session.set("perPageRedelivery", perPageNew);
    },
    sortBy(sortByNew) {
      this.$session.set("sortByRedelivery", sortByNew);
    },
    sortDesc(sortDescNew) {
      this.$session.set("sortDescRedelivery", sortDescNew);
    },
    // stackedStatus(stackedStatusNew) {
    //   this.$session.set("stackedStatusWarehouseDistribution", stackedStatusNew)
    // }
  },
  methods: {
    ...mapActions({
      getDeliveryOrder: "delivery/getDeliveryOrder",
    }),
    onPageChange(page) {
      this.currentPage = page;
      console.log("this.currentPage", this.currentPage);
      this.queryDelivery();
    },
    //METHOD FOR MOBILE VIEW if needed same with other mobile view
    processMetadata(metadata) {
      let { totalData } = metadata;
      console.log("totalData", totalData);
      this.totalRows = totalData;
      this.tableLoading = false;
    },
    btnForMobile(filter) {
      this.deliveryStatus = filter;
      this.btnBackMobile = "hide-on-desktop";
      this.conditionView = "ml-md-auto show-on-desktop";
      this.btnshow = false;
      this.showDropdown = false;
      this.showSortBy = true;
    },
    actBackMobile() {
      this.btnBackMobile = "hide-at-all";
      this.conditionView = "ml-md-auto hide-on-mobile";
      this.btnshow = true;
      this.showDropdown = true;
      this.showSortBy = false;
    },
    //END METHOD FOR MOBILE VIEW
    dates(date) {
      return dateFormat(date);
    },
    queryDelivery() {
      let wantedStatus = [
        "Re-Delivery",
      ];
      this.getDeliveryOrder({
        shippingPoint:this.shippingPoint,
        page: this.currentPage,
        entry: this.perPage,
        sapDo: this.search,
        status:
          this.deliveryStatus == "All" ? wantedStatus : this.deliveryStatus,
        delivDate: this.delivDate,
      }).then((data) => {
        this.processMetadata(data.metadata)
        this.isLoading = false
      }).catch((e) => {
        this.$bvToast.toast("Load data failed, please reload the page.", {
        title: "Warning",
        variant: "danger",
        solid: true,
        });
        console.log(e);
      });
    },
    cleanQuery() {
      this.delivDate = "";
      this.queryDelivery()
    },
    doFiltering() {
      this.isLoading = true
      //this.search = value;
      this.currentPage = 1;
      this.queryDelivery();
    },
    fireFiltering(value) {
      this.delivDate = value;
      //this is for datepicker load store data with query
      // this.$store.dispatch("delivery/getDeliveryOrder", { delivDate: value });
      this.currentPage = 1;
      this.queryDelivery();
    },
    statusFiltering(value) {
      this.deliveryStatus = value;
      // this.currentPage = 1;
      // this.queryDelivery();
    },
    
    toDetailPage(data) {
      let idDO = data._id;

        this.$router.push({
          name: "warehouse-detail-for-completed",
          params: { id: idDO, from: "redelivery-daikin",status:this.deliveryStatus },
        });
     
    },
  },
};
</script>
<style scope>
.hide-at-all {
  display: none !important;
}

/* For mobile phones: */
@media (max-width: 761px) {
  .hide-on-mobile {
    display: none !important;
  }
}

/* For computer: */
@media (min-width: 761px) {
  .hide-on-desktop {
    display: none !important;
  }
  .show-on-desktop {
    display: inline !important;
  }
}
</style>
